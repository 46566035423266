import { Box, Flex, Text, useBoolean } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle } from "react";
import { BadgeModal, BadgeModalButton, BadgeModalProps } from "./BadgeModal";
import { Selective } from "@/generated/graphql";
import MedalIcon from "@/icons/Medal";
import { selectiveSortMap } from "@/utils/sorting";
const content: Record<Selective, {
  label: string;
  description?: string;
}> = {
  [Selective.Highly]: {
    label: "Yes",
    description: "Opportunities are classified as highly selective if they are very competitive to get into, having less than a 15% acceptance rate. Most programs do not disclose their admission rate so this information is imperfect but developed from the knowledge of experienced college admissions experts with the intent to more openly share this industry insider information with all high schoolers and their families."
  },
  [Selective.None]: {
    label: "No"
  }
};
interface SelectiveItemProps {
  selective: Selective;
}
const SelectiveItem = ({
  selective
}: SelectiveItemProps) => {
  return content[selective].description ? <Box paddingTop="20px" background="white" data-sentry-element="Box" data-sentry-component="SelectiveItem" data-sentry-source-file="SelectiveBadge.tsx">
      <Flex gap="4px" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="SelectiveBadge.tsx">
        <Text fontSize={18} fontFamily="nunito" fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="SelectiveBadge.tsx">
          {content[selective].label}
        </Text>
        <MedalIcon boxSize={7} data-sentry-element="MedalIcon" data-sentry-source-file="SelectiveBadge.tsx" />
      </Flex>
      <Box margin="12px 0" data-sentry-element="Box" data-sentry-source-file="SelectiveBadge.tsx">
        <Text fontSize={18} data-sentry-element="Text" data-sentry-source-file="SelectiveBadge.tsx">{content[selective].description}</Text>
      </Box>
    </Box> : null;
};
const SelectiveModal = ({
  open,
  onClose
}: BadgeModalProps) => <BadgeModal sortMap={selectiveSortMap} title="Highly Selective" values={Selective} open={open} onClose={onClose} renderItem={value => <SelectiveItem selective={value} />} data-sentry-element="BadgeModal" data-sentry-component="SelectiveModal" data-sentry-source-file="SelectiveBadge.tsx" />;
interface SelectiveBadgeProps {
  selective: Selective;
  includeLabel?: boolean;
  includeLabelIcon?: boolean;
}
export type SelectiveHandle = {
  toggleModal: () => void;
};
export const SelectiveBadge = forwardRef<SelectiveHandle, SelectiveBadgeProps>(({
  selective,
  includeLabel
}, ref) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  useImperativeHandle(ref, () => ({
    toggleModal() {
      toggle();
    }
  }));
  return <>
        <BadgeModalButton onClick={toggle}>
          {selective == Selective.Highly ? <MedalIcon boxSize={6} color="snow.blue-medium" /> : null}
          {includeLabel && <Text>{content[selective].label}</Text>}
        </BadgeModalButton>
        <SelectiveModal open={open} onClose={off} />
      </>;
});