import { Button, HStack, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react";
import NextLink from "next/link";
import AskIcon from "@/icons/Ask";
import ChatIcon from "@/icons/Chat";
import EmailIcon from "@/icons/Email";
import VideoIcon from "@/icons/Video";
import { useDevice } from "@/ui/hooks";
export interface AskSnowdayModalProps {
  open: boolean;
  onClose: () => void;
}
export const AskSnowdayModal = ({
  open,
  onClose
}: AskSnowdayModalProps) => {
  const {
    isMobile
  } = useDevice();
  return <Modal isOpen={open} onClose={onClose} data-sentry-element="Modal" data-sentry-component="AskSnowdayModal" data-sentry-source-file="AskSnowdayModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="AskSnowdayModal.tsx" />
      <ModalContent width={{
      base: "296px",
      md: "420px"
    }} data-sentry-element="ModalContent" data-sentry-source-file="AskSnowdayModal.tsx">
        <ModalHeader paddingTop="40px" paddingBottom={5} color="snow.blue-dark" data-sentry-element="ModalHeader" data-sentry-source-file="AskSnowdayModal.tsx">
          <HStack spacing={1} alignItems="center" justifyContent="center" data-sentry-element="HStack" data-sentry-source-file="AskSnowdayModal.tsx">
            <AskIcon data-sentry-element="AskIcon" data-sentry-source-file="AskSnowdayModal.tsx" />
            <Text fontSize="18px" fontFamily="nunito" align="center" data-sentry-element="Text" data-sentry-source-file="AskSnowdayModal.tsx">
              Ask Snowday
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="AskSnowdayModal.tsx" />
        <ModalBody paddingTop={0} px={{
        base: 5,
        md: 10
      }} overflowY="auto" paddingBottom={10} data-sentry-element="ModalBody" data-sentry-source-file="AskSnowdayModal.tsx">
          <VStack spacing={4} alignItems="stretch" data-sentry-element="VStack" data-sentry-source-file="AskSnowdayModal.tsx">
            <Text align="center" width={{
            base: "256px",
            md: "340px"
          }} data-sentry-element="Text" data-sentry-source-file="AskSnowdayModal.tsx">
              Get free program recommendations from an expert
            </Text>

            <VStack spacing={3} data-sentry-element="VStack" data-sentry-source-file="AskSnowdayModal.tsx">
              <Link href="https://calendly.com/asksnowday/30min" as={NextLink} isExternal w="100%" data-sentry-element="Link" data-sentry-source-file="AskSnowdayModal.tsx">
                <Button leftIcon={<VideoIcon />} data-sentry-element="Button" data-sentry-source-file="AskSnowdayModal.tsx">Schedule video call</Button>
              </Link>
              {isMobile ? <Link href="sms:+19292778129" as={NextLink} w="100%">
                  <Button leftIcon={<ChatIcon />} variant="secondary">
                    Send text message
                  </Button>
                </Link> : null}
              <Link href="mailto:ask@snow.day?subject=Ask Snowday Request" as={NextLink} w="100%" data-sentry-element="Link" data-sentry-source-file="AskSnowdayModal.tsx">
                <Button leftIcon={<EmailIcon />} variant="secondary" data-sentry-element="Button" data-sentry-source-file="AskSnowdayModal.tsx">
                  Send email
                </Button>
              </Link>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>;
};