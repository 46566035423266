import { Flex, Text } from "@chakra-ui/react";
import { Deadline, Maybe } from "@/generated/graphql";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { PredictedDeadlineBadge } from "@/ui/badges/PredictedBadge";
import { getNextDeadline, getSummarizedTextFromLODeadlines } from "@/utils/domain";
interface LocationProps {
  deadlines?: Maybe<Maybe<Deadline>[]>;
}
export default function DeadlineComponent({
  deadlines = []
}: LocationProps) {
  const deadlineValue = getSummarizedTextFromLODeadlines(deadlines ?? []);
  const nextDeadline = getNextDeadline(deadlines ?? []);
  return deadlineValue ? <Flex flexDirection="row" alignItems="center" gap={1} data-sentry-element="Flex" data-sentry-component="DeadlineComponent" data-sentry-source-file="DeadlineComponent.tsx">
      <SnowdayIcons name="Alarm" boxSize={{
      base: 4,
      md: 5
    }} data-sentry-element="SnowdayIcons" data-sentry-source-file="DeadlineComponent.tsx" />
      <Text color={deadlineValue.color} fontSize={{
      base: "15px",
      md: "16px"
    }} fontStyle="italic" lineHeight={{
      base: "15px",
      md: "16px"
    }} fontWeight={700} data-sentry-element="Text" data-sentry-source-file="DeadlineComponent.tsx">
        Deadline:
      </Text>
      <Text fontSize={{
      base: "15px",
      md: "16px"
    }} color={deadlineValue.color} lineHeight={{
      base: "15px",
      md: "16px"
    }} data-sentry-element="Text" data-sentry-source-file="DeadlineComponent.tsx">
        {deadlineValue.text}
      </Text>
      <PredictedDeadlineBadge predicted={nextDeadline?.predicted ?? false} data-sentry-element="PredictedDeadlineBadge" data-sentry-source-file="DeadlineComponent.tsx" />
    </Flex> : null;
}