import { useBoolean, Button, IconButton } from "@chakra-ui/react";
import { AskSnowdayModal } from "./AskSnowdayModal";
import AskIcon from "@/icons/Ask";
interface AskSnowdayButtonProps {
  includeLabel?: boolean;
  color?: string;
}
export type SelectiveHandle = {
  toggleModal: () => void;
};
export const AskSnowdayButton = ({
  includeLabel,
  color
}: AskSnowdayButtonProps) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  return <>
      {includeLabel ? <Button leftIcon={<AskIcon />} onClick={toggle} paddingX={{
      md: 10
    }} width={{
      base: "100%",
      md: "fit-content"
    }}>
          Ask Snowday
        </Button> : <IconButton variant="unstyled" size="xs" aria-label="Open Ask Snowday Modal" icon={<AskIcon color={color} />} onClick={toggle} />}
      <AskSnowdayModal open={open} onClose={off} data-sentry-element="AskSnowdayModal" data-sentry-source-file="AskSnowdayButton.tsx" />
    </>;
};