import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { AskSnowdayButton } from "./AskSnowdayButton";
import { useDevice } from "@/ui/hooks";
import BgBottom2 from "@/ui/waves/BgBottom2";
import BgTop2 from "@/ui/waves/BgTop2";
type AskSnowdayBlockProps = {
  light?: boolean;
};
export const AskSnowdayBlock = ({
  light
}: AskSnowdayBlockProps) => {
  const {
    isMobile
  } = useDevice();
  return <Box w={{
    base: "100vw",
    md: "100%"
  }} data-sentry-element="Box" data-sentry-component="AskSnowdayBlock" data-sentry-source-file="AskSnowdayBlock.tsx">
      {light ? null : isMobile ? <BgTop2 /> : null}
      <VStack p={{
      base: 6,
      md: 10
    }} gap={4} bg="snow.white" rounded={4} backgroundColor={light ? undefined : "snow.blue-light"} margin-left={{
      base: "50%"
    }} data-sentry-element="VStack" data-sentry-source-file="AskSnowdayBlock.tsx">
        <Text align="center" color="snow.blue-dark" fontWeight="bold" variant="block" data-sentry-element="Text" data-sentry-source-file="AskSnowdayBlock.tsx">
          Contact Our Experts <br />
          For Free Program <br />
          Recommendations
        </Text>
        <AskSnowdayButton includeLabel data-sentry-element="AskSnowdayButton" data-sentry-source-file="AskSnowdayBlock.tsx" />
      </VStack>
      {light ? null : isMobile ? <BgBottom2 /> : null}
    </Box>;
};