import { IconProps } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { FinancialAccessibility } from "@/generated/graphql";
interface MoneybagIconsProps extends IconProps {
  variant?: FinancialAccessibility;
}
export const dynamicComponents = {
  [FinancialAccessibility.A]: dynamic(() => import("./A")),
  [FinancialAccessibility.APlus]: dynamic(() => import("./A_PLUS")),
  [FinancialAccessibility.AMinus]: dynamic(() => import("./A_MINUS")),
  [FinancialAccessibility.BPlus]: dynamic(() => import("./B_PLUS")),
  [FinancialAccessibility.B]: dynamic(() => import("./B")),
  [FinancialAccessibility.BMinus]: dynamic(() => import("./B_MINUS")),
  [FinancialAccessibility.CPlus]: dynamic(() => import("./C_PLUS")),
  [FinancialAccessibility.None]: dynamic(() => import("./NONE")),
  Default: dynamic(() => import("./Default"))
};
export default function MoneybagIcons({
  variant,
  ...props
}: MoneybagIconsProps) {
  const DynamicComponent = dynamicComponents[variant ?? "Default"];
  return <DynamicComponent {...props} data-sentry-element="DynamicComponent" data-sentry-component="MoneybagIcons" data-sentry-source-file="index.tsx" />;
}