import { HStack, Text } from "@chakra-ui/react";
import SnowdayIcons from "../icons/SnowdayIcons";
interface Props {
  alreadySaved: boolean;
  onClick: () => void;
}
export const SaveButton = ({
  alreadySaved,
  onClick
}: Props) => {
  const renderIcon = () => {
    if (alreadySaved) {
      return <SnowdayIcons name="Checkmark" color="snow.blue-light-medium" boxSize={5} marginRight={1} />;
    } else {
      return <SnowdayIcons name="Pin" color="snow.blue-light-medium" boxSize={6} />;
    }
  };
  return <>
      <HStack gap={0} onClick={onClick} cursor="pointer" data-sentry-element="HStack" data-sentry-source-file="SaveButton.tsx">
        {renderIcon()}
        <Text color="snow.blue-light-medium" fontSize="small" fontWeight="bold" lineHeight="12px" data-sentry-element="Text" data-sentry-source-file="SaveButton.tsx">
          {alreadySaved ? "Saved" : "Save"}
        </Text>
      </HStack>
    </>;
};