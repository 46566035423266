import { SortOrdering } from "../sorting/types"
import { FilterState } from "@/filters/state/reducer"
import { iconTypes } from "@/icons/SnowdayIcons"

export interface TopSearchDataType {
  name: string
  icon: keyof typeof iconTypes
  sort?: SortOrdering[]
  filter?: FilterState
}

export interface TopSearchViewProps {
  data: TopSearchDataType[]
}

export enum SearchTypes {
  INTERESTS = "interests",
  PROVIDERS = "providers",
  LEARNING_PATHS = "learning-paths",
  LEARNING_OPPORTUNITIES = "learning-opportunities",
}
