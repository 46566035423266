import { isNil, omitBy } from "lodash"

import { partition, unique } from "./array"
import { not, truthy } from "./fp"
import { getDateFromNow, titlecase } from "./string"
import { FilterState } from "@/filters/state/reducer"
import { FiltersType } from "@/filters/types"
import {
  Deadline,
  LearningOpportunityFilter,
  LearningOpportunityType,
  Maybe,
  Provider,
  Session,
} from "@/generated/graphql"

const locationEquals = (first: string, second: string): boolean =>
  first.toLowerCase() === second.toLowerCase()

const isOnline = (location: string) => locationEquals(location, "online")

export const getLocationFromSessions = (sessions: Session[]): string | null => {
  const filtered = sessions.map((session) => session.location?.name ?? "Online")

  const [first, second] = unique(filtered.filter(not(isOnline)))

  return (
    [second ? "In person" : first, filtered.find(isOnline)]
      .filter(truthy)
      .join(" and ") || null
  )
}

export const getLoTypeDisplayText = (type: LearningOpportunityType) => {
  switch (type) {
    case LearningOpportunityType.Competition: {
      return "Competition"
    }
    case LearningOpportunityType.SchoolYearProgram: {
      return "School Year Program"
    }
    case LearningOpportunityType.SummerProgram: {
      return "Summer Program"
    }
  }
}

interface DeadlineRenderData {
  color: string
  text: string
}

export const getSummarizedTextFromLODeadlines = (
  deadlines: Maybe<Deadline>[] = [],
): DeadlineRenderData | null => {
  const hasRollingDeadlines =
    deadlines.filter((deadline) => deadline?.rolling).length > 0

  if (hasRollingDeadlines) {
    return {
      color: "snow.blue-dark",
      text: "rolling",
    }
  }

  if (
    deadlines.length === 0 ||
    deadlines.filter((deadline) => deadline?.date != null).length === 0
  ) {
    return null
  }

  const today = new Date()

  const thirtyDays = new Date()
  thirtyDays.setDate(thirtyDays.getDate() + 30)

  const nextDeadline: Deadline | undefined = getNextDeadline(deadlines)
  const nextDeadlineDate = nextDeadline
    ? new Date(nextDeadline.date)
    : undefined

  const daysUntilNextDeadline = nextDeadlineDate
    ? Math.round(
        (nextDeadlineDate.getTime() - today.getTime()) / (1000 * 3600 * 24),
      )
    : undefined

  if (nextDeadlineDate && daysUntilNextDeadline !== undefined) {
    if (daysUntilNextDeadline === 0) {
      return {
        color: "snow.blue-dark",
        text: "today",
      }
    } else if (daysUntilNextDeadline < 30) {
      return {
        color: "snow.blue-dark",
        text: `in ${daysUntilNextDeadline} days`,
      }
    } else {
      return {
        color: "snow.blue-dark",
        text: `on ${formatDate(nextDeadlineDate)}`,
      }
    }
  } else {
    return {
      color: "gray.400",
      text: "passed",
    }
  }
}

export const getNextDeadline = (
  deadlines: Maybe<Deadline>[],
): Deadline | undefined => {
  let nextDeadline: Deadline | undefined = undefined
  let nextDeadlineDate: Date | undefined = undefined

  for (const deadline of deadlines) {
    if (!deadline) {
      continue
    }

    const deadlineDate = new Date(deadline.date)

    if (
      new Date() < deadlineDate &&
      (!nextDeadlineDate || deadlineDate < nextDeadlineDate)
    ) {
      nextDeadline = deadline
      nextDeadlineDate = deadlineDate
    }
  }

  return nextDeadline
}

type GroupedSessions = { [key: string]: Session[] }

export const groupSessions = (sessions: Session[]): GroupedSessions =>
  partition(
    [...sessions].sort((a, b) => {
      // first we sort the sessions based on their location name (lowercased)
      if (a.location && b.location) {
        return a.location.name.toLowerCase() < b.location.name.toLowerCase()
          ? -1
          : 1
      }
      // Next sort on startDate
      if (a.startDate !== b.startDate) {
        return a.startDate < b.startDate ? -1 : 1
      }
      // And finally on endDate
      if (a.endDate === b.endDate) {
        return 0
      }

      return a.endDate < b.endDate ? -1 : 1
    }),
    ({ location }) => titlecase(location ? location.name : "Online"),
  )

const isCurrentYear = (date: Date) => {
  return date.getFullYear() === new Date().getFullYear()
}

export const formatDateRange = (start: Date, end: Date, showDay = true) => {
  const showYear = !isCurrentYear(start) || !isCurrentYear(end)

  return `${formatDateHelper(start, {
    showYear,
    showDay,
  })} - ${formatDateHelper(end, {
    showYear,
    showDay,
  })}`
}

export const formatDate = (date: Date, showDay?: boolean) => {
  const showYear = !isCurrentYear(date)

  return formatDateHelper(date, { showYear, showDay })
}

const formatDateHelper = (
  date: Date,
  options: { showYear?: boolean; showDay?: boolean },
) => {
  const showYear = options.showYear ?? true
  const showDay = options.showDay ?? true

  return date.toLocaleDateString("en-US", {
    timeZone: "GMT",
    month: "short",
    day: showDay ? "numeric" : undefined,
    year: showYear ? "numeric" : undefined,
  })
}

export const mapFilterToAPI = (
  filterState: FilterState & {
    [FiltersType.PROVIDERS]?: Provider[] | Provider
  },
): LearningOpportunityFilter => {
  return omitBy(
    {
      availability: availabilityFilter(filterState[FiltersType.AVAILABILITY]),
      collegeCredit: collegeCreditFilter(
        filterState[FiltersType.COLLEGE_CREDIT],
      ),
      deadline: deadlineFilter(filterState[FiltersType.DEADLINE]),
      financialAccessibility: financialAccessibilityFilter(
        filterState[FiltersType.FINANCIAL_ACCESSIBILITY],
      ),
      grade: gradeFilter(filterState[FiltersType.GRADES]),
      interest: interestFilter(filterState[FiltersType.INTERESTS]),
      location: locationFilter(
        filterState[FiltersType.LOCATION],
        filterState[FiltersType.LOCATION_RADIUS],
      ),
      online: onlineFilter(
        filterState[FiltersType.INCLUDE_ONLINE],
        filterState[FiltersType.ONLINE_ONLY],
      ),
      restriction: restrictionFilter(
        filterState[FiltersType.ELIGIBILITY],
        filterState[FiltersType.NO_RESTRICTIONS],
      ),
      selectivity: selectivityFilter(filterState[FiltersType.SELECTIVE]),
      provider: providersFilter(filterState[FiltersType.PROVIDERS]),
      type: typeFilter(filterState[FiltersType.TYPE]),
    },
    isNil,
  )
}

function availabilityFilter(
  state: FilterState[FiltersType.AVAILABILITY] | undefined,
): LearningOpportunityFilter["availability"] {
  if (
    state === undefined ||
    state.start === undefined ||
    state.end === undefined
  ) {
    return undefined
  }

  return {
    range: {
      start: state.start,
      end: state.end,
    },
  }
}

function collegeCreditFilter(
  state: FilterState[FiltersType.COLLEGE_CREDIT] | undefined,
): LearningOpportunityFilter["collegeCredit"] {
  if (state === undefined || !Array.isArray(state) || state.length === 0) {
    return undefined
  }

  return !!state
}

function deadlineFilter(
  state: FilterState[FiltersType.DEADLINE] | undefined,
): LearningOpportunityFilter["deadline"] {
  if (state === undefined) {
    return undefined
  }

  return {
    range: {
      start: new Date().toDateString(),
      end: getDateFromNow("months", parseInt(state)).toDateString(),
    },
  }
}

function financialAccessibilityFilter(
  financialAccessibility:
    | FilterState[FiltersType.FINANCIAL_ACCESSIBILITY]
    | undefined,
): LearningOpportunityFilter["financialAccessibility"] {
  if (
    financialAccessibility === undefined ||
    !Array.isArray(financialAccessibility) ||
    financialAccessibility.length === 0
  ) {
    return undefined
  }

  return {
    in: financialAccessibility,
  }
}

function gradeFilter(
  grades: FilterState[FiltersType.GRADES] | undefined,
): LearningOpportunityFilter["grade"] {
  if (grades === undefined || !Array.isArray(grades) || grades.length === 0) {
    return undefined
  }

  return {
    in: grades,
  }
}

function interestFilter(
  interests: FilterState[FiltersType.INTERESTS] | undefined,
): LearningOpportunityFilter["interest"] {
  if (
    interests === undefined ||
    !Array.isArray(interests) ||
    interests.length === 0
  ) {
    return undefined
  }

  return {
    name: {
      in: interests.map((interest) => interest.name),
    },
  }
}
function providersFilter(
  providers: Provider[] | Provider | undefined,
): LearningOpportunityFilter["interest"] {
  if (
    providers === undefined ||
    !Array.isArray(providers) ||
    providers.length === 0
  ) {
    return undefined
  }

  return {
    name: {
      in: providers.map((provider) => provider.name),
    },
  }
}

function locationFilter(
  location: FilterState[FiltersType.LOCATION] | undefined,
  radius: FilterState[FiltersType.LOCATION_RADIUS] | undefined,
): LearningOpportunityFilter["location"] {
  if (location === undefined) {
    return undefined
  }

  return {
    radius: {
      longitude: location.longitude,
      latitude: location.latitude,
      distance: {
        value: radius ?? 25,
        unit: "MILES",
      },
    },
  }
}

function onlineFilter(
  includeOnline: FilterState[FiltersType.INCLUDE_ONLINE] | undefined,
  onlineOnly: FilterState[FiltersType.ONLINE_ONLY] | undefined,
): LearningOpportunityFilter["online"] {
  return {
    includeOnline: includeOnline ?? true,
    onlineOnly: onlineOnly ?? false,
  }
}

function restrictionFilter(
  restriction: FilterState[FiltersType.ELIGIBILITY] | undefined,
  noRestriction: FilterState[FiltersType.NO_RESTRICTIONS] | undefined,
): LearningOpportunityFilter["restriction"] {
  const inArray = []
  const applyNoRestrictions =
    noRestriction === true || (Array.isArray(noRestriction) && noRestriction[0])

  if (applyNoRestrictions) {
    inArray.push(null)
  }

  if (
    restriction !== undefined &&
    Array.isArray(restriction) &&
    restriction.length !== 0
  ) {
    inArray.push(...restriction)
  }

  if (inArray.length === 0) {
    return undefined
  }

  return {
    in: inArray,
  }
}

function selectivityFilter(
  selectivity: FilterState[FiltersType.SELECTIVE] | undefined,
): LearningOpportunityFilter["selectivity"] {
  if (
    selectivity === undefined ||
    !Array.isArray(selectivity) ||
    selectivity.length === 0
  ) {
    return undefined
  }

  return {
    in: selectivity,
  }
}

function typeFilter(
  types: FilterState[FiltersType.TYPE] | undefined,
): LearningOpportunityFilter["type"] {
  if (types === undefined || !Array.isArray(types) || types.length === 0) {
    return undefined
  }

  return {
    in: types,
  }
}
