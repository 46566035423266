import {
  FinancialAccessibility,
  LearningOpportunityType,
  Selective,
} from "@/generated/graphql"

/**
 * We're just going to define non-mutating sort once. JS
 * Array.sort is mutating by default and that leads to
 * trouble with side-effects. If the values are primitives
 * we don't need a sorting function per-se, but the default
 * behaviour is weird.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#return_value
 */
export const sort = <T>(values: T[], sorter: (value: T) => Sortable) =>
  [...values].sort((a, b) => {
    const [newA, newB] = [a, b].map(sorter)
    return newA === newB ? 0 : newA < newB ? -1 : 1
  })

export type Sortable = string | number

export const financialSortMap: Record<FinancialAccessibility, number> = {
  [FinancialAccessibility.APlus]: 1,
  [FinancialAccessibility.A]: 2,
  [FinancialAccessibility.AMinus]: 3,
  [FinancialAccessibility.BPlus]: 4,
  [FinancialAccessibility.B]: 5,
  [FinancialAccessibility.BMinus]: 6,
  [FinancialAccessibility.CPlus]: 7,
  [FinancialAccessibility.None]: 8,
}

export const selectiveSortMap: Record<Selective, number> = {
  [Selective.Highly]: 1,
  [Selective.None]: 2,
}

export const loTypeSortMap: Record<LearningOpportunityType, number> = {
  [LearningOpportunityType.SchoolYearProgram]: 1,
  [LearningOpportunityType.SummerProgram]: 2,
  [LearningOpportunityType.Competition]: 3,
}
