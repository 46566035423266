import { Box, Flex, Text, useBoolean } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle } from "react";
import { BadgeModal, BadgeModalButton, BadgeModalProps } from "./BadgeModal";
import { FinancialAccessibility } from "@/generated/graphql";
import MoneybagIcons from "@/icons/Moneybag";
import { financialSortMap } from "@/utils/sorting";
const content: { [key in FinancialAccessibility]: {
  label: string;
  description?: string;
} } = {
  [FinancialAccessibility.APlus]: {
    label: "A+",
    description: "Tuition Free + Stipend covering most additional costs (i.e. travel, meals, books or supplies, etc.)"
  },
  [FinancialAccessibility.A]: {
    label: "A",
    description: "Tuition Free."
  },
  [FinancialAccessibility.AMinus]: {
    label: "A-",
    description: "Very low cost relative to equivalent programs. Financial aid offered to all who meet transparent and generous need requirements."
  },
  [FinancialAccessibility.BPlus]: {
    label: "B+",
    description: "Moderately low cost relative to equivalent programs. Financial aid offered to all who meet need requirements. Few additional expenses (i.e. boarding not required)."
  },
  [FinancialAccessibility.B]: {
    label: "B",
    description: "Average relative cost. Financial aid offered to some who meet need requirements. Some programs may be higher than average cost but offset them with financial aid policies."
  },
  [FinancialAccessibility.BMinus]: {
    label: "B-",
    description: "Average relative cost. Financial aid offered to some who meet need requirements. Some programs may be higher than average cost but offset them with financial aid policies."
  },
  [FinancialAccessibility.CPlus]: {
    label: "C+",
    description: "High relative cost. Either no financial aid offered or sparse financial aid offered."
  },
  [FinancialAccessibility.None]: {
    label: "None",
    description: " Not Available Yet. This program does not or has not yet publicly provided sufficient public or scholarship information for a rating."
  }
};
interface FinancialAccessibilityItemProps {
  accessibility: FinancialAccessibility;
}
const FinancialAccessibilityItem = ({
  accessibility
}: FinancialAccessibilityItemProps) => {
  return <Box paddingTop="20px" background="white" data-sentry-element="Box" data-sentry-component="FinancialAccessibilityItem" data-sentry-source-file="FinancialAccessibilityBadge.tsx">
      <Flex gap="4px" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="FinancialAccessibilityBadge.tsx">
        <Text fontSize={18} fontFamily="nunito" fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="FinancialAccessibilityBadge.tsx">
          Grade:
        </Text>
        <MoneybagIcons variant={accessibility} boxSize={7} data-sentry-element="MoneybagIcons" data-sentry-source-file="FinancialAccessibilityBadge.tsx" />
      </Flex>
      {content[accessibility]?.description && <Box margin="12px 0">
          <Text fontSize={18}>{content[accessibility].description}</Text>
        </Box>}
    </Box>;
};
export const FinancialAccessibilityModal = ({
  open,
  onClose
}: BadgeModalProps) => <BadgeModal sortMap={financialSortMap} title="Financial Accessibility" values={FinancialAccessibility} open={open} onClose={onClose} renderItem={value => <FinancialAccessibilityItem accessibility={value} />} data-sentry-element="BadgeModal" data-sentry-component="FinancialAccessibilityModal" data-sentry-source-file="FinancialAccessibilityBadge.tsx" />;
interface FinancialAccessibilityBadgeProps {
  accessibility: FinancialAccessibility;
  includeLabel?: boolean;
  includeLabelIcon?: boolean;
  size?: number;
}
export type FinancialAccessibilityHandle = {
  toggleModal: () => void;
};
export const FinancialAccessibilityBadge = forwardRef<FinancialAccessibilityHandle, FinancialAccessibilityBadgeProps>(({
  accessibility,
  includeLabel,
  includeLabelIcon,
  size = 6
}, ref) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  useImperativeHandle(ref, () => ({
    toggleModal() {
      toggle();
    }
  }));
  return <>
      <BadgeModalButton onClick={toggle}>
        {includeLabelIcon ? <MoneybagIcons variant={accessibility} boxSize={size} color="snow.blue-medium" /> : <MoneybagIcons boxSize={size} color="snow.blue-medium" />}
        {includeLabel && <Text>{content[accessibility].label}</Text>}
      </BadgeModalButton>
      <FinancialAccessibilityModal open={open} onClose={off} />
    </>;
});