import { HStack } from "@chakra-ui/react";
import { isEmpty, reject, uniqueId } from "lodash";
import LOResultCard, { PartialLearningOpportunity } from "../components/result/LOResultCard";
import { SearchResultsProps } from "../components/SearchResults";
import { FiltersType } from "@/filters/types";
import { AskSnowdayBlock } from "@/ui/askSnowday/AskSnowdayBlock";
import { SaveButton } from "@/ui/buttons/SaveButton";

/**
 * Function to insert AskSnowdayBlock components into the array of learning
 * opportunities.
 *
 * @param los Array of learning opportunities
 * @returns Array of learning opportunities with AskSnowdayBlock inserted
 * every 15th element (and) after the 5th element. If the array is less than 5
 * elements long, only one AskSnowdayBlock is inserted after the nth element.
 */
export const insertAskSnowdayBlocks = (los: (PartialLearningOpportunity & {
  saved: boolean;
})[], onSaveButtonClick: (id: string) => void) => {
  // Create an array of elements to be returned.
  const elements = los.map(item => <LOResultCard key={item.identifier} learningOpportunity={item}>
      <HStack justifyContent="flex-end">
        <SaveButton alreadySaved={item.saved} onClick={() => {
        onSaveButtonClick(item.identifier);
      }} />
      </HStack>
    </LOResultCard>);
  if (elements.length < 5) {
    return [...elements, <AskSnowdayBlock key={"ask-snow-1"} />];
  }
  return elements.flatMap((item, index) => {
    return index + 1 === 5 || !((index - 4) % 15) ? [item, <AskSnowdayBlock key={"ask-snow-" + index} />] : [item];
  });
};
export const countLabel = (value: number): string => {
  if (value < 100) {
    return `${value}`;
  }
  const roundedValue = Math.floor(value / 100) * 100;
  return `${roundedValue}${value > roundedValue ? "+" : ""}`;
};
export const generateResultUrl = (type: SearchResultsProps["type"], name: SearchResultsProps["name"], identifier: string): string => {
  if (["learning-opportunities", "learning-paths"].includes(type)) {
    return `/${type}/${encodeURIComponent(identifier)}`;
  }
  const filter = {
    [type === "providers" ? FiltersType.PROVIDERS : FiltersType.INTERESTS]: [{
      identifier: uniqueId(),
      name: name
    }]
  };
  const params = [["filter", filter ? JSON.stringify(filter) : ""]];
  return `/search/results?${new URLSearchParams(reject(params, isEmpty))}`;
};