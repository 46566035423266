import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { Fragment, ReactElement, ReactNode, useMemo } from "react";
import { sort, Sortable } from "@/utils/sorting";
interface BadgeModalButtonProps {
  onClick: () => void;
  children: ReactNode;
}

/**
 * This is a custom button to escape the link overlay
 *
 * @see https://github.com/chakra-ui/chakra-ui/issues/5673
 */
export const BadgeModalButton = ({
  children,
  onClick
}: BadgeModalButtonProps) => <Flex gap={1} as="button" cursor="pointer" onClick={e => {
  e.preventDefault();
  onClick();
}} style={{
  zIndex: 2,
  margin: 0
}} alignItems="center" data-sentry-element="Flex" data-sentry-component="BadgeModalButton" data-sentry-source-file="BadgeModal.tsx">
    {children}
  </Flex>;
export interface BadgeModalProps {
  open: boolean;
  onClose: () => void;
}
type InnerModalProps<T extends string | number> = BadgeModalProps & {
  sortMap: Record<T, Sortable>;
  title: string;
  values: Record<string, T>;
  renderItem: (entry: T) => ReactElement;
};
export const BadgeModal = <T extends string | number,>({
  open,
  onClose,
  title,
  values,
  renderItem,
  sortMap
}: InnerModalProps<T>) => {
  const sortedValues = useMemo(() => sort(Object.entries(values), ([_, value]) => sortMap[value] ?? 0), [sort, values]);
  return <Modal isOpen={open} onClose={onClose} data-sentry-element="Modal" data-sentry-component="BadgeModal" data-sentry-source-file="BadgeModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="BadgeModal.tsx" />
      <ModalContent height={{
      base: "520px",
      md: "560px",
      lg: "480px",
      xl: "520px"
    }} data-sentry-element="ModalContent" data-sentry-source-file="BadgeModal.tsx">
        <ModalHeader paddingTop="40px" paddingBottom={5} data-sentry-element="ModalHeader" data-sentry-source-file="BadgeModal.tsx">
          <Text fontSize="18px" fontFamily="nunito" align="center" data-sentry-element="Text" data-sentry-source-file="BadgeModal.tsx">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="BadgeModal.tsx" />
        <ModalBody paddingTop={0} overflowY="auto" paddingBottom="20px" data-sentry-element="ModalBody" data-sentry-source-file="BadgeModal.tsx">
          <Flex flexDirection="column" gap="1px" maxHeight="100%" overflow="auto" className="scrollbar" data-sentry-element="Flex" data-sentry-source-file="BadgeModal.tsx">
            {sortedValues.map(([key, value]) => <Fragment key={key}>{renderItem(value)}</Fragment>)}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>;
};