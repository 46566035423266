import { SVGProps } from "react";
const BgTop2 = (props: SVGProps<SVGSVGElement>) => <svg viewBox="0 0 834 81" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="BgTop2" data-sentry-source-file="BgTop2.tsx">
    <mask id="a" style={{
    maskType: "alpha"
  }} maskUnits="userSpaceOnUse" x={0} y={0} width={834} height={81} data-sentry-element="mask" data-sentry-source-file="BgTop2.tsx">
      <path fill="#D9D9D9" d="M0 0h834v81H0z" data-sentry-element="path" data-sentry-source-file="BgTop2.tsx" />
    </mask>
    <g data-sentry-element="g" data-sentry-source-file="BgTop2.tsx">
      <path d="M0 27.119c167.476 45.1 317.335-40.033 483.846-20.016C650.357 27.119 692.105 98.75 834 72.22v445.245c-179.541 59.33-294.168-10.754-452.715-22.912C222.738 482.395 83.738 552.918 0 475.982V27.119Z" fill="#E6F1FB" data-sentry-element="path" data-sentry-source-file="BgTop2.tsx" />
    </g>
  </svg>;
export default BgTop2;