import { Box, Flex, Text } from "@chakra-ui/react";
import { Session } from "@/generated/graphql";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { getLocationFromSessions } from "@/utils/domain";
interface LocationProps {
  sessions: Session[];
}
export default function LocationComponent({
  sessions
}: LocationProps) {
  return sessions.length ? <Flex flexDirection="row" alignItems="center" justifyContent={"flex-start"} gap={1} data-sentry-element="Flex" data-sentry-component="LocationComponent" data-sentry-source-file="LocationComponent.tsx">
      <Box h="100%" display="flex" alignItems="flex-start" data-sentry-element="Box" data-sentry-source-file="LocationComponent.tsx">
        <SnowdayIcons name="Location" boxSize={{
        base: 4,
        md: 5
      }} data-sentry-element="SnowdayIcons" data-sentry-source-file="LocationComponent.tsx" />
      </Box>

      <Text color="snow.blue-dark" fontSize={{
      base: "15px",
      md: "16px"
    }} lineHeight={{
      base: "15px",
      md: "16px"
    }} data-sentry-element="Text" data-sentry-source-file="LocationComponent.tsx">
        {getLocationFromSessions(sessions)}
      </Text>
    </Flex> : null;
}