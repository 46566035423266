import { Box, Flex, Text, useBoolean } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle } from "react";
import { BadgeModal, BadgeModalButton, BadgeModalProps } from "./BadgeModal";
import SnowdayIcons from "@/icons/SnowdayIcons";
interface PredictedItemProps {
  predicted: boolean;
  description: string;
}
const PredictedItem = ({
  predicted,
  description
}: PredictedItemProps) => {
  return predicted ? <Box paddingTop="20px" background="white" data-sentry-element="Box" data-sentry-component="PredictedItem" data-sentry-source-file="PredictedBadge.tsx">
      <Flex gap="4px" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="PredictedBadge.tsx">
        <Text fontSize={18} fontFamily="nunito" fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="PredictedBadge.tsx">
          Predicted
        </Text>
        <SnowdayIcons name="Predicted" boxSize={6} color="snow.blue" data-sentry-element="SnowdayIcons" data-sentry-source-file="PredictedBadge.tsx" />
      </Flex>
      <Box margin="12px 0" data-sentry-element="Box" data-sentry-source-file="PredictedBadge.tsx">
        <Text fontSize={18} data-sentry-element="Text" data-sentry-source-file="PredictedBadge.tsx">{description}</Text>
      </Box>
    </Box> : null;
};
interface PredictedModalProps extends BadgeModalProps {
  title: string;
  description: string;
}
const PredictedModal = ({
  title,
  description,
  open,
  onClose
}: PredictedModalProps) => <BadgeModal sortMap={{
  true: 1,
  false: 2
}} title={title} values={{
  true: "true",
  false: "false"
}} open={open} onClose={onClose} renderItem={value => <PredictedItem predicted={value === "true"} description={description} />} data-sentry-element="BadgeModal" data-sentry-component="PredictedModal" data-sentry-source-file="PredictedBadge.tsx" />;
interface PredictedBadgeProps {
  predicted: boolean;
  title: string;
  description: string;
}
export type SelectiveHandle = {
  toggleModal: () => void;
};
export const PredictedBadge = forwardRef<SelectiveHandle, PredictedBadgeProps>(({
  title,
  predicted,
  description
}, ref) => {
  const [open, {
    toggle,
    off
  }] = useBoolean();
  useImperativeHandle(ref, () => ({
    toggleModal() {
      toggle();
    }
  }));
  return <>
        <BadgeModalButton onClick={toggle}>
          {predicted ? <SnowdayIcons name="Predicted" color="snow.blue" boxSize={5} mt="-10px" ml="-5px" /> : null}
        </BadgeModalButton>
        <PredictedModal title={title} description={description} open={open} onClose={off} />
      </>;
});
export const PredictedSessionBadge = ({
  predicted
}: {
  predicted: boolean;
}) => {
  return <PredictedBadge predicted={predicted} title="Session Dates" description="These dates are predicted as the provider has not announced this year’s session dates. We estimate them based on previous years. Please note that the dates may not be accurate, and the session might not occur or could be discontinued." data-sentry-element="PredictedBadge" data-sentry-component="PredictedSessionBadge" data-sentry-source-file="PredictedBadge.tsx" />;
};
export const PredictedDeadlineBadge = ({
  predicted
}: {
  predicted: boolean;
}) => {
  return <PredictedBadge predicted={predicted} title="Deadline Dates" description="This deadline is predicted, as the provider has not announced this year’s deadlines. We estimate it based on previous years, so it may not be accurate." data-sentry-element="PredictedBadge" data-sentry-component="PredictedDeadlineBadge" data-sentry-source-file="PredictedBadge.tsx" />;
};