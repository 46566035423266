import { Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { Maybe, Session, SessionDateType } from "@/generated/graphql";
import SnowdayIcons from "@/icons/SnowdayIcons";
import { PredictedSessionBadge } from "@/ui/badges/PredictedBadge";
import { formatDate, formatDateRange } from "@/utils/domain";
interface SessionDatesProps {
  sessions?: Maybe<Maybe<Session>[]>;
}
const validateSessionDates = (sessions: Maybe<Maybe<Session>[]> = []): Session[] => (sessions ?? []).filter((session: Maybe<Session>): session is Session => !!session).filter(({
  dateType,
  startDate,
  endDate
}) => dateType === SessionDateType.Rolling || startDate && endDate);
const variousDates = (sessions: Maybe<Session>[]) => {
  const [first, ...rest] = validateSessionDates(sessions);
  return [first, ...rest].reverse().reduce((result, {
    startDate,
    endDate
  }) => {
    if (!equalDates(new Date(endDate), result.end)) {
      result.variousEnd = true;
    }
    if (!equalDates(new Date(startDate), result.start)) {
      result.variousStart = true;
    }
    result.end = new Date(endDate);
    result.start = new Date(startDate);
    return result;
  }, {
    variousStart: false,
    variousEnd: false,
    start: new Date(first.startDate),
    end: new Date(first.endDate),
    dateType: first.dateType
  });
};
const equalDates = (a: Date, b: Date) => a.toLocaleDateString() === b.toLocaleDateString();
const formatSessionDate = (sessions: Maybe<Session>[]) => {
  const rolling = sessions.filter(session => session?.dateType === SessionDateType.Rolling).length > 0;
  if (rolling) {
    return "Rolling Start";
  }
  const {
    variousStart,
    variousEnd,
    start,
    end,
    dateType
  } = variousDates(sessions);
  const showDay = dateType === SessionDateType.Dates;
  const sameDate = equalDates(start, end);
  return sameDate ? formatDate(start, showDay) : variousStart || variousEnd ? "Various" : formatDateRange(start, end, showDay);
};
export default function SessionDatesComponent({
  sessions = []
}: SessionDatesProps) {
  if (!validateSessionDates(sessions).length) {
    return null;
  }
  const formattedDates = useMemo(() => formatSessionDate(sessions ?? []), [sessions]);
  return <Flex flexDirection="row" alignItems="center" gap={1} data-sentry-element="Flex" data-sentry-component="SessionDatesComponent" data-sentry-source-file="SessionDatesComponent.tsx">
      <SnowdayIcons name="Calendar" boxSize={{
      base: 4,
      md: 5
    }} data-sentry-element="SnowdayIcons" data-sentry-source-file="SessionDatesComponent.tsx" />
      <Text fontSize={{
      base: "15px",
      md: "16px"
    }} lineHeight={{
      base: "15px",
      md: "16px"
    }} data-sentry-element="Text" data-sentry-source-file="SessionDatesComponent.tsx">
        {formattedDates}
      </Text>
      <PredictedSessionBadge predicted={(sessions?.length === 1 && sessions[0]?.predicted) ?? false} data-sentry-element="PredictedSessionBadge" data-sentry-source-file="SessionDatesComponent.tsx" />
    </Flex>;
}