import { Icon, IconProps } from "@chakra-ui/react";
const MedalIcon = (props: IconProps) => <Icon width="24px" height="24px" fill="none" {...props} data-sentry-element="Icon" data-sentry-component="MedalIcon" data-sentry-source-file="Medal.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="Medal.tsx">
      <path d="m16.5 8.49 3-5.902a.75.75 0 0 0 0-.75.75.75 0 0 0-.645-.338h-3.96a.75.75 0 0 0-.667.413L12 6.293l-2.25-4.38a.75.75 0 0 0-.645-.413h-3.96a.75.75 0 0 0-.645.36.75.75 0 0 0 0 .75l3 5.903a.75.75 0 0 0 .668.405h1.695a3.315 3.315 0 0 0-.165 1.027 3.97 3.97 0 0 0 0 .42 6.338 6.338 0 1 0 4.62 0c.02-.144.033-.29.037-.435a3.51 3.51 0 0 0-.105-1.035h1.643a.75.75 0 0 0 .607-.405Zm-.69 6.75-1.912 1.868.45 2.64L12 18.488l-2.37 1.245.45-2.64-1.912-1.868 2.647-.382L12 12.435l1.185 2.408 2.625.397Zm-2.962-5.302v.052A6.479 6.479 0 0 0 12 9.908c-.273 0-.546.02-.817.06a.098.098 0 0 1 0-.045c-.01-.37.104-.73.322-1.028h1.05c.217.298.327.66.315 1.028l-.022.015Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="Medal.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Medal.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="Medal.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="Medal.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default MedalIcon;